html {
    overflow-y: auto;
    overflow-x: hidden;
}

.container {
    width: 1600px;
}

@media screen and (max-width: 1660px) {
    html {
        font-size: 9px;
    }
    .container {
        width: 1280px;
    }
}

@media screen and (max-width: 1300px) {
    html {
        font-size: 8px;
    }
    .container {
        width: 1024px;
    }
}

@media screen and (max-width: 1080px) {
    html {
        font-size: 7px;
    }
    .container {
        width: 760px;
    }
}

@media screen and (max-width: 768px) {
    html {
        font-size: 12px;
    }
    .container {
        width: 100%;
        padding: 0 2rem;
    }
}

@media screen and (max-width: 568px) { 
    html {
        font-size: 8px;
    }
}