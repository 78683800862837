@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: 'Jost';
    src: url('./assets/fonts/Jost-Regular.eot');
    src: url('./assets/fonts/Jost-Regular.eot?#iefix') format('embedded-opentype'),
        url('./assets/fonts/Jost-Regular.woff2') format('woff2'),
        url('./assets/fonts/Jost-Regular.woff') format('woff'),
        url('./assets/fonts/Jost-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'JostBold';
    src: url('./assets/fonts/Jost-Bold.eot');
    src: url('./assets/fonts/Jost-Bold.eot?#iefix') format('embedded-opentype'),
        url('./assets/fonts/Jost-Bold.woff2') format('woff2'),
        url('./assets/fonts/Jost-Bold.woff') format('woff'),
        url('./assets/fonts/Jost-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

html {
    font-family: 'Jost', sans-serif;
    background-color: #fff;
    scroll-behavior: smooth;
    font-size: 10px;
}

body {
    font-size: 1.8rem;
    color: #505050;
}